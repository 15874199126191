@import url("https://fonts.googleapis.com/css2?family=Carter+One&family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.heading {
  font-weight: 700;
  font-size: 1.4rem;
  padding: 1rem;
  text-align: center;
}

.mix-blend-multiply {
  mix-blend-mode: multiply;
}

nav {
  display: flex;
  padding: 1rem 1.2rem;
  justify-content: space-between;
  align-items: center;
  height: 100px;
}
nav img.logo {
  width: 60px;
  padding: 0.5rem;
}
nav .nav-brand {
  font-size: 1.5rem;
  font-family: "Carter One";
  color: #155e75;
  margin: 0;
  display: inline-block;
}
nav .nav-items {
  display: none;
  padding: 0;
  margin: 0;
}
nav .nav-items .nav-item {
  list-style: none;
  font-size: 1rem;
  font-weight: 600;
}
nav .nav-items .nav-item button {
  font-size: 0.9rem;
  font-weight: 600;
  padding: 1rem 1.5rem;
  border: none;
  background: none;
  border-radius: 8px;
  cursor: pointer;
}
nav .nav-items .nav-item .btn-secondary a {
  color: black;
  text-decoration: none;
  font-weight: 600;
}
nav .nav-items .nav-item .active {
  color: #155e75;
  font-weight: bold;
}
nav .nav-items .nav-item .btn-primary {
  border: solid 1px #155e75;
  padding: 0.5rem 1rem;
  box-shadow: 1px 1px 20px #a5f3fc;
}
nav .nav-items .nav-item .btn-primary a {
  font-weight: bold;
  color: #155e75;
  text-decoration: none;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 20;
  background: white;
}
.sidebar button {
  padding: 1.5rem 0.9rem;
  background: none;
  border: none;
  font-size: 2rem;
  text-align: right;
  display: block;
  margin-left: auto;
  font-weight: 600;
  color: #52525B;
}
.sidebar button img {
  height: 1.2rem;
  width: auto;
}
.sidebar ul li {
  list-style: none;
  padding: 1rem;
  font-weight: 600;
  font-size: 1rem;
}
.sidebar ul li a {
  color: #27272A;
  text-decoration: none;
}
.sidebar ul li .active {
  font-size: 1.2rem;
  color: #155e75;
  font-weight: 800;
}
.sidebar ul li.btn-primary a {
  font-weight: bold;
  color: #155e75;
  text-decoration: none;
  border: solid 1px #155e75;
  padding: 0.5rem 1rem;
  box-shadow: 1px 1px 20px #a5f3fc;
  border-radius: 8px;
  display: inline-block;
}

.ais-Highlight-highlighted {
  color: #27272A;
  background-color: #a5f3fc;
  padding: 0;
}

.hero-content h1 {
  margin-top: 1.5rem;
  font-weight: 700;
  margin-right: 2rem;
  margin-bottom: 0;
  font-size: 2rem;
}
.hero-content h1 span {
  color: #155e75;
  font-family: "Carter One";
}
.hero-content p.subHeading {
  margin: 2rem 0;
  font-size: 0.9rem;
}
.hero-content .search-wrapper {
  padding-bottom: 1rem;
  position: relative;
}
.hero-content .search-wrapper #searchbox {
  min-height: 45px;
}
.hero-content .search-wrapper input {
  padding: 0.6rem 1.5rem;
  width: 100%;
  max-width: 400px;
  outline: unset;
  border: solid 1px #71717A;
  border-radius: 8px;
  background-color: unset;
}
.hero-content .search-wrapper .btn-search {
  padding: 1rem 1.5rem;
  background-color: unset;
  background-color: #155e75;
  border: solid 1px #155e75;
  border-radius: 8px;
  font-weight: 600;
  color: white;
  text-decoration: none;
}
.hero-content .search-wrapper #hits {
  position: absolute;
  top: 58px;
  width: 100%;
  max-width: 400px;
}
.hero-content .search-wrapper #hits .ais-Hits {
  background: white;
  border-radius: 8px;
  border: 1px solid #E4E4E7;
}
.hero-content .search-wrapper #hits ol {
  margin: 0;
}
.hero-content .search-wrapper #hits li, .hero-content .search-wrapper #hits .ais-Hits--empty {
  list-style: none;
  padding: 0.5rem;
  border-bottom: solid 1px #E4E4E7;
}
.hero-content .search-wrapper #hits li a, .hero-content .search-wrapper #hits .ais-Hits--empty a {
  color: #27272A;
  text-decoration: none;
  display: block;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.hero-content .search-wrapper #hits li a .fa, .hero-content .search-wrapper #hits .ais-Hits--empty a .fa {
  font-size: x-small;
  color: #A1A1AA;
}
.hero-content .search-wrapper #hits ol {
  padding: 0;
}
.hero-content .search-wrapper #hits .no-result {
  margin: 0;
  font-size: small;
}
.hero-content .btn-login-now {
  margin: 1rem 0;
  color: #155e75;
  font-weight: bold;
  border: unset;
  text-align: left;
  background-color: unset;
  text-decoration: underline;
}
.hero-content .btn-login-now img {
  height: 0.8rem;
  width: 0.8rem;
  margin-left: 0.2rem;
}

.apply-for-job-section {
  background-color: #edfdfe;
}
.apply-for-job-section .apply-job-steps {
  background-color: #cffafe;
  border-radius: 8px;
}
.apply-for-job-section .apply-job-steps h3 {
  font-weight: 700;
  font-size: 1rem;
}
.apply-for-job-section .apply-job-steps p {
  margin-left: 1rem;
  font-size: 0.9rem;
}

.recent-opening {
  padding: 1rem;
}

.fa-angle-left, .fa-angle-right {
  cursor: pointer;
}

.row1, .row2 {
  display: flex;
  width: auto;
  overflow-x: auto;
  padding: 1rem;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.row1::-webkit-scrollbar {
  display: none;
}

.row2::-webkit-scrollbar {
  display: none;
}

.job-opening {
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 1px 1px 20px #E4E4E7;
  min-width: 250px;
  margin-left: 1.5rem;
  cursor: pointer;
}
.job-opening .job-opening-shimmer {
  background-color: #e0e0e0;
  min-height: 100px;
}
.job-opening h3.job-title {
  font-size: 1rem;
  font-weight: 600;
}
.job-opening p {
  margin: 0;
  font-size: 0.8rem;
}

.accociated-company-section {
  background-color: #edfdfe;
}
.accociated-company-section h3 {
  font-size: 0.8rem;
}
.accociated-company-section h3 span {
  color: #155e75;
}

.digital-services h3 {
  font-size: 0.8rem;
}
.digital-services .btn-border-secondary {
  font-size: 1rem;
  font-weight: 600;
  padding: 1rem 1.5rem;
  border: none;
  background: none;
  border-radius: 8px;
  cursor: pointer;
  color: #155e75;
  border: solid 1px #155e75;
  text-decoration: none;
}

ol li {
  padding: 0.4rem 0;
  font-weight: 400;
  text-align: left;
  font-size: 0.8rem;
}

span.logo-text {
  color: #155e75;
  font-family: "Carter One", "Poppins";
}

.employer-section {
  background-color: #edfdfe;
}
.employer-section h3 {
  font-size: 2rem;
  font-weight: 800;
  color: #155e75;
}
.employer-section p {
  margin-top: 1rem;
  margin-bottom: 2rem;
  max-width: 400px;
}
.employer-section .btn-register {
  padding: 1rem 1.5rem;
  color: #155e75;
  font-weight: bold;
  border-radius: 8px;
  background: none;
  border: solid 1px #155e75;
  text-decoration: none;
}

.footer {
  background-color: #27272A;
  color: white;
  font-size: 0.8rem;
}
.footer .logo {
  width: 60px;
}
.footer .footer-logo-text {
  font-size: 1.6rem;
  font-family: "Carter One";
  margin-left: 0.5rem;
}
.footer h5 {
  font-weight: 600;
  font-size: 1rem;
}
.footer .social-links {
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: center;
}
.footer .social-links li {
  list-style: none;
  padding: 0.5rem;
}
.footer .social-links li .social-icons {
  width: 24px;
  height: 24px;
}
.footer .quick-links {
  padding: 0;
}
.footer .quick-links li {
  padding: 0.3rem 0;
  list-style: none;
}
.footer .quick-links li a {
  color: white;
  text-decoration: none;
}
.footer .subfooter {
  background: #18181B;
  padding: 0.5rem;
}
.footer .subfooter a, .footer .subfooter p {
  color: white;
  text-decoration: none;
  font-size: 0.6rem;
  padding: 0.5rem;
  margin: 0;
  text-align: center;
  display: inline;
}
.footer .subfooter .tgits {
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
  font-size: 0.6rem;
}

#comments {
  height: 200px;
}

#contactus-submit-btn {
  background-color: #155e75;
  color: white;
  font-weight: bold;
}

.page-heading {
  font-size: 1.5rem;
}

.policy-heading {
  font-size: 1.1rem;
  font-weight: 600;
  margin-top: 1rem;
}

.policy-para {
  font-size: 0.8rem;
}

.policy-list li {
  font-size: 0.8rem;
}

@media (min-width: 768px) {
  nav img.logo {
    width: 80px;
    padding: 0.5rem;
  }
  .hero-content h1 {
    margin-top: 4rem;
  }
  .hero-content p {
    width: 75%;
  }
  .footer .social-links {
    display: flex;
    padding: 0;
    align-items: center;
    justify-content: space-between;
  }
}
@media (min-width: 1024px) {
  nav .hamburger-icon {
    display: none;
  }
  nav .nav-items {
    display: flex;
    align-items: center;
    justify-content: end;
    flex-wrap: nowrap;
  }
  nav .nav-items .nav-item {
    font-size: 0.8rem;
  }
  nav .nav-items .nav-item button {
    font-size: 0.8rem;
  }
  .hero-content h1 {
    margin-top: 6rem;
    font-weight: 800;
    margin-right: 2rem;
    font-size: 3rem;
    width: 75%;
  }
  .hero-content p {
    width: 75%;
  }
  .hero-content .search-wrapper {
    padding-bottom: 4rem;
  }
  .footer .footer-logo {
    font-size: 2.5rem;
  }
  .policy-heading {
    margin-top: 2rem;
  }
  .policy-para {
    font-size: 0.9rem;
  }
  .policy-list li {
    font-size: 0.9rem;
  }
}
@media (min-width: 1200px) {
  .nav-items .nav-item {
    font-size: 1rem;
  }
  .nav-items .nav-item button {
    font-size: 1rem;
  }
}
@media (min-width: 1536px) {
  nav .nav-items .nav-item button {
    font-size: 1rem;
    padding: 1rem 1.5rem;
  }
}/*# sourceMappingURL=styles.css.map */