@import "https://fonts.googleapis.com/css2?family=Carter+One&family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap";
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Poppins, sans-serif;
}

.heading {
  text-align: center;
  padding: 1rem;
  font-size: 1.4rem;
  font-weight: 700;
}

.mix-blend-multiply {
  mix-blend-mode: multiply;
}

nav {
  justify-content: space-between;
  align-items: center;
  height: 100px;
  padding: 1rem 1.2rem;
  display: flex;
}

nav img.logo {
  width: 60px;
  padding: .5rem;
}

nav .nav-brand {
  color: #155e75;
  margin: 0;
  font-family: Carter One;
  font-size: 1.5rem;
  display: inline-block;
}

nav .nav-items {
  margin: 0;
  padding: 0;
  display: none;
}

nav .nav-items .nav-item {
  font-size: 1rem;
  font-weight: 600;
  list-style: none;
}

nav .nav-items .nav-item button {
  cursor: pointer;
  background: none;
  border: none;
  border-radius: 8px;
  padding: 1rem 1.5rem;
  font-size: .9rem;
  font-weight: 600;
}

nav .nav-items .nav-item .btn-secondary a {
  color: #000;
  font-weight: 600;
  text-decoration: none;
}

nav .nav-items .nav-item .active {
  color: #155e75;
  font-weight: bold;
}

nav .nav-items .nav-item .btn-primary {
  border: 1px solid #155e75;
  padding: .5rem 1rem;
  box-shadow: 1px 1px 20px #a5f3fc;
}

nav .nav-items .nav-item .btn-primary a {
  color: #155e75;
  font-weight: bold;
  text-decoration: none;
}

.sidebar {
  z-index: 20;
  background: #fff;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.sidebar button {
  text-align: right;
  color: #52525b;
  background: none;
  border: none;
  margin-left: auto;
  padding: 1.5rem .9rem;
  font-size: 2rem;
  font-weight: 600;
  display: block;
}

.sidebar button img {
  width: auto;
  height: 1.2rem;
}

.sidebar ul li {
  padding: 1rem;
  font-size: 1rem;
  font-weight: 600;
  list-style: none;
}

.sidebar ul li a {
  color: #27272a;
  text-decoration: none;
}

.sidebar ul li .active {
  color: #155e75;
  font-size: 1.2rem;
  font-weight: 800;
}

.sidebar ul li.btn-primary a {
  color: #155e75;
  border: 1px solid #155e75;
  border-radius: 8px;
  padding: .5rem 1rem;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
  box-shadow: 1px 1px 20px #a5f3fc;
}

.ais-Highlight-highlighted {
  color: #27272a;
  background-color: #a5f3fc;
  padding: 0;
}

.hero-content h1 {
  margin-top: 1.5rem;
  margin-bottom: 0;
  margin-right: 2rem;
  font-size: 2rem;
  font-weight: 700;
}

.hero-content h1 span {
  color: #155e75;
  font-family: Carter One;
}

.hero-content p.subHeading {
  margin: 2rem 0;
  font-size: .9rem;
}

.hero-content .search-wrapper {
  padding-bottom: 1rem;
  position: relative;
}

.hero-content .search-wrapper #searchbox {
  min-height: 45px;
}

.hero-content .search-wrapper input {
  outline: unset;
  background-color: unset;
  border: 1px solid #71717a;
  border-radius: 8px;
  width: 100%;
  max-width: 400px;
  padding: .6rem 1.5rem;
}

.hero-content .search-wrapper .btn-search {
  background-color: unset;
  color: #fff;
  background-color: #155e75;
  border: 1px solid #155e75;
  border-radius: 8px;
  padding: 1rem 1.5rem;
  font-weight: 600;
  text-decoration: none;
}

.hero-content .search-wrapper #hits {
  width: 100%;
  max-width: 400px;
  position: absolute;
  top: 58px;
}

.hero-content .search-wrapper #hits .ais-Hits {
  background: #fff;
  border: 1px solid #e4e4e7;
  border-radius: 8px;
}

.hero-content .search-wrapper #hits ol {
  margin: 0;
}

.hero-content .search-wrapper #hits li, .hero-content .search-wrapper #hits .ais-Hits--empty {
  border-bottom: 1px solid #e4e4e7;
  padding: .5rem;
  list-style: none;
}

.hero-content .search-wrapper #hits li a, .hero-content .search-wrapper #hits .ais-Hits--empty a {
  color: #27272a;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.hero-content .search-wrapper #hits li a .fa, .hero-content .search-wrapper #hits .ais-Hits--empty a .fa {
  color: #a1a1aa;
  font-size: x-small;
}

.hero-content .search-wrapper #hits ol {
  padding: 0;
}

.hero-content .search-wrapper #hits .no-result {
  margin: 0;
  font-size: small;
}

.hero-content .btn-login-now {
  color: #155e75;
  border: unset;
  text-align: left;
  background-color: unset;
  margin: 1rem 0;
  font-weight: bold;
  text-decoration: underline;
}

.hero-content .btn-login-now img {
  width: .8rem;
  height: .8rem;
  margin-left: .2rem;
}

.apply-for-job-section {
  background-color: #edfdfe;
}

.apply-for-job-section .apply-job-steps {
  background-color: #cffafe;
  border-radius: 8px;
}

.apply-for-job-section .apply-job-steps h3 {
  font-size: 1rem;
  font-weight: 700;
}

.apply-for-job-section .apply-job-steps p {
  margin-left: 1rem;
  font-size: .9rem;
}

.recent-opening {
  padding: 1rem;
}

.fa-angle-left, .fa-angle-right {
  cursor: pointer;
}

.row1, .row2 {
  scrollbar-width: none;
  -ms-overflow-style: none;
  width: auto;
  padding: 1rem;
  display: flex;
  overflow-x: auto;
}

.row1::-webkit-scrollbar {
  display: none;
}

.row2::-webkit-scrollbar {
  display: none;
}

.job-opening {
  cursor: pointer;
  border-radius: 8px;
  min-width: 250px;
  margin-left: 1.5rem;
  padding: 2rem;
  box-shadow: 1px 1px 20px #e4e4e7;
}

.job-opening .job-opening-shimmer {
  background-color: #e0e0e0;
  min-height: 100px;
}

.job-opening h3.job-title {
  font-size: 1rem;
  font-weight: 600;
}

.job-opening p {
  margin: 0;
  font-size: .8rem;
}

.accociated-company-section {
  background-color: #edfdfe;
}

.accociated-company-section h3 {
  font-size: .8rem;
}

.accociated-company-section h3 span {
  color: #155e75;
}

.digital-services h3 {
  font-size: .8rem;
}

.digital-services .btn-border-secondary {
  cursor: pointer;
  color: #155e75;
  background: none;
  border: 1px solid #155e75;
  border-radius: 8px;
  padding: 1rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
}

ol li {
  text-align: left;
  padding: .4rem 0;
  font-size: .8rem;
  font-weight: 400;
}

span.logo-text {
  color: #155e75;
  font-family: Carter One, Poppins;
}

.employer-section {
  background-color: #edfdfe;
}

.employer-section h3 {
  color: #155e75;
  font-size: 2rem;
  font-weight: 800;
}

.employer-section p {
  max-width: 400px;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.employer-section .btn-register {
  color: #155e75;
  background: none;
  border: 1px solid #155e75;
  border-radius: 8px;
  padding: 1rem 1.5rem;
  font-weight: bold;
  text-decoration: none;
}

.footer {
  color: #fff;
  background-color: #27272a;
  font-size: .8rem;
}

.footer .logo {
  width: 60px;
}

.footer .footer-logo-text {
  margin-left: .5rem;
  font-family: Carter One;
  font-size: 1.6rem;
}

.footer h5 {
  font-size: 1rem;
  font-weight: 600;
}

.footer .social-links {
  justify-content: center;
  align-items: center;
  padding: 0;
  display: flex;
}

.footer .social-links li {
  padding: .5rem;
  list-style: none;
}

.footer .social-links li .social-icons {
  width: 24px;
  height: 24px;
}

.footer .quick-links {
  padding: 0;
}

.footer .quick-links li {
  padding: .3rem 0;
  list-style: none;
}

.footer .quick-links li a {
  color: #fff;
  text-decoration: none;
}

.footer .subfooter {
  background: #18181b;
  padding: .5rem;
}

.footer .subfooter a, .footer .subfooter p {
  color: #fff;
  text-align: center;
  margin: 0;
  padding: .5rem;
  font-size: .6rem;
  text-decoration: none;
  display: inline;
}

.footer .subfooter .tgits {
  cursor: pointer;
  font-size: .6rem;
  font-weight: 600;
  text-decoration: underline;
}

#comments {
  height: 200px;
}

#contactus-submit-btn {
  color: #fff;
  background-color: #155e75;
  font-weight: bold;
}

.page-heading {
  font-size: 1.5rem;
}

.policy-heading {
  margin-top: 1rem;
  font-size: 1.1rem;
  font-weight: 600;
}

.policy-para, .policy-list li {
  font-size: .8rem;
}

@media (width >= 768px) {
  nav img.logo {
    width: 80px;
    padding: .5rem;
  }

  .hero-content h1 {
    margin-top: 4rem;
  }

  .hero-content p {
    width: 75%;
  }

  .footer .social-links {
    justify-content: space-between;
    align-items: center;
    padding: 0;
    display: flex;
  }
}

@media (width >= 1024px) {
  nav .hamburger-icon {
    display: none;
  }

  nav .nav-items {
    flex-wrap: nowrap;
    justify-content: end;
    align-items: center;
    display: flex;
  }

  nav .nav-items .nav-item, nav .nav-items .nav-item button {
    font-size: .8rem;
  }

  .hero-content h1 {
    width: 75%;
    margin-top: 6rem;
    margin-right: 2rem;
    font-size: 3rem;
    font-weight: 800;
  }

  .hero-content p {
    width: 75%;
  }

  .hero-content .search-wrapper {
    padding-bottom: 4rem;
  }

  .footer .footer-logo {
    font-size: 2.5rem;
  }

  .policy-heading {
    margin-top: 2rem;
  }

  .policy-para, .policy-list li {
    font-size: .9rem;
  }
}

@media (width >= 1200px) {
  .nav-items .nav-item, .nav-items .nav-item button {
    font-size: 1rem;
  }
}

@media (width >= 1536px) {
  nav .nav-items .nav-item button {
    padding: 1rem 1.5rem;
    font-size: 1rem;
  }
}

/*# sourceMappingURL=index.04b149de.css.map */
