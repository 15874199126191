$indigo-800:#3730A3;
$indigo-700:#4338CA;
$indigo-600:#4F46E5;
$indigo-500:#6366F1;
$indigo-400:#818CF8;
$indigo-300:#A5B4FC;
$indigo-200:#C7D2FE;
$indigo-100:#E0E7FF;
$indigo-50:#eef2ffb0;

$teal-950:#042f2e;
$teal-900:#134e4a;
$teal-800:#115e59;
$teal-700:#0f766e;
$teal-600:#0d9488;
$teal-500:#14b8a6;
$teal-400:#2dd4bf;
$teal-300:#5eead4;
$teal-200:#99f6e4;
$teal-100:#ccfbf1;
$teal-50:#f0fdfa;

$cyan-800:#155e75;
$cyan-700:#0e7490;
$cyan-600:#0891b2;
$cyan-500:#06b6d4;
$cyan-400:#22d3ee;
$cyan-300:#67e8f9;
$cyan-200:#a5f3fc;
$cyan-100:#cffafe;
$cyan-50:#edfdfe;

$zinc-900:#18181B;
$zinc-800:#27272A;
$zinc-700:#3F3F46;
$zinc-600:#52525B;
$zinc-500:#71717A;
$zinc-400:#A1A1AA;
$zinc-300:#D4D4D8;
$zinc-200:#E4E4E7;
$zinc-100:#F4F4F5;
$zinc-50:#FAFAFA;

@import url('https://fonts.googleapis.com/css2?family=Carter+One&family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

.heading {
    font-weight: 700;
    font-size: 1.4rem;
    padding: 1rem;
    text-align: center;
}
.mix-blend-multiply{
    mix-blend-mode: multiply;
}
nav{
    display: flex;
    padding: 1rem 1.2rem;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    img.logo{
        width: 60px;
        padding: 0.5rem;
    }
    .nav-brand{
        font-size: 1.5rem;
        font-family: "Carter One";
        color: $cyan-800;
        margin: 0;
        display: inline-block;
    }
    .nav-items{
        display: none;
        padding: 0;
        margin: 0;
        .nav-item{
            list-style: none;
            font-size: 1rem;
            font-weight: 600;
            button{
                font-size: 0.9rem;
                font-weight: 600;
                padding: 1rem 1.5rem;
                border: none;
                background: none;
                border-radius: 8px;
                cursor: pointer;
            }
            .btn-secondary{
                    a{
                    color: black; 
                    text-decoration: none;
                    font-weight: 600;
                }
                // border: solid 1px $cyan-800;                     
            }
            .active{
                color: $cyan-800;
                font-weight: bold;
            }

            .btn-primary{
                border: solid 1px $cyan-800;
                padding: 0.5rem 1rem;
                box-shadow: 1px 1px 20px $cyan-200;
                a{
                    font-weight: bold;
                    color: $cyan-800;
                    text-decoration: none;
                }    
            
            }
        }
    }
    
}

.sidebar{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 20;
    background:white;
    button{
        padding: 1.5rem 0.9rem;
        background: none;
        border: none;
        font-size: 2rem;
        text-align: right;
        display: block;
        margin-left: auto;
        font-weight: 600;
        color: $zinc-600;
        img{
            height: 1.2rem;
            width: auto;
        }
    }

    ul{
        li{
            list-style: none;
            padding: 1rem;
            font-weight: 600;
            font-size: 1rem;
            a{
                color: $zinc-800;
                text-decoration: none;
            }.active{
            font-size: 1.2rem;
            color: $cyan-800;
                font-weight: 800;
            }
        }
        li.btn-primary{
            
            a{
                font-weight: bold;
                color: $cyan-800;
                text-decoration: none;
                border: solid 1px $cyan-800;
                padding: 0.5rem 1rem;
                box-shadow: 1px 1px 20px $cyan-200;
                border-radius: 8px;
                display: inline-block;
            }    
        }
    }
}
.ais-Highlight-highlighted{
    color:$zinc-800;
    background-color: $cyan-200;
    padding: 0;
}
.hero-content{
    h1{
        margin-top: 1.5rem;
        font-weight: 700;
        margin-right: 2rem;
        margin-bottom: 0;
        font-size: 2rem;
        span{
            color: $cyan-800;
            font-family: 'Carter One';
        }
    }
    p.subHeading{
        margin: 2rem 0;
        font-size: 0.9rem;
    }
    .search-wrapper{
        padding-bottom: 1rem;
        position: relative;
        #searchbox{
            min-height: 45px;
        }
        input{
            padding: 0.6rem 1.5rem;
            width: 100%;
            max-width: 400px;
            outline: unset;
           border: solid 1px $zinc-500;
            border-radius: 8px;
            background-color: unset;
        }
        .btn-search{
            padding: 1rem 1.5rem;
            background-color: unset;
            background-color: $cyan-800;
            border: solid 1px $cyan-800;
            border-radius: 8px;
            font-weight: 600;
            color: white;
            text-decoration: none;           
        }
        #hits{
            position: absolute;
            top: 58px;
            width: 100%;
            max-width: 400px;
            .ais-Hits{
                background: white;
                border-radius: 8px;
                border: 1px solid $zinc-200;
            }
            ol{
                margin: 0;
            }
            li, .ais-Hits--empty{
                list-style: none;
                padding: 0.5rem;
                border-bottom: solid 1px $zinc-200;
                a{
                    color: $zinc-800;
                    text-decoration: none;
                    display: block;
                    cursor: pointer;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .fa{
                        font-size: x-small;
                        color: $zinc-400;
                    }
                }
                
            }
            ol{
                padding: 0;
            }
            .no-result{
                margin: 0;
                font-size: small;
            }
        }
    }
    .btn-login-now{
        margin: 1rem 0;
        // padding: 1rem 1.5rem;
        color: $cyan-800;
        font-weight: bold;
        border: unset;
        text-align: left;
        // border-radius: 8px;
        background-color: unset;
        // border: solid 1px $cyan-800;
        text-decoration: underline;
        img{
            height: 0.8rem;
            width: 0.8rem;
            margin-left: 0.2rem;
        }
    }
}
.apply-for-job-section{
    background-color: $cyan-50;
    .apply-job-steps{
        // border: solid 1px $cyan-800;
        background-color: $cyan-100;
        border-radius: 8px;
        h3{ 
            font-weight: 700;
            font-size: 1rem;
        }
        p{
            margin-left: 1rem;
            font-size: 0.9rem;

        }
    }
}

.recent-opening{
    padding: 1rem;
}

.fa-angle-left,.fa-angle-right{
    cursor: pointer;
}
.row1, .row2{
    display: flex;
    width: auto;
    overflow-x: auto;
    padding: 1rem;
    scrollbar-width:none;
    -ms-overflow-style: none;
}
.row1::-webkit-scrollbar {
    display: none;
  }
  .row2::-webkit-scrollbar {
    display: none;
  }
.job-opening{
    padding:2rem;
    border-radius: 8px;
    box-shadow: 1px 1px 20px $zinc-200;
    min-width: 250px;
    margin-left: 1.5rem;
    cursor: pointer;
    .job-opening-shimmer{
        background-color: #e0e0e0;
        min-height: 100px;
    }
    h3.job-title{
        font-size: 1rem;
        font-weight: 600;
    }
    p{
        margin: 0;
        font-size: 0.8rem;  
    }


}
.accociated-company-section{
    background-color: $cyan-50;
    h3{
        font-size: 0.8rem;
        span{
            color: $cyan-800;
        }
    }
}

.digital-services{
    h3{
        font-size: 0.8rem; 
    }
    .btn-border-secondary{
        font-size: 1rem;
        font-weight: 600;
        padding: 1rem 1.5rem;
        border: none;
        background: none;
        border-radius: 8px;
        cursor: pointer;
        color: $cyan-800;  
        border: solid 1px $cyan-800; 
        text-decoration: none;                   
    }
}
ol{
    li{
        padding: 0.4rem 0;
        font-weight: 400;
        text-align: left;
        font-size: 0.8rem;
    }
}
span.logo-text{
    color: $cyan-800;
    font-family: 'Carter One', 'Poppins';
}


.employer-section{
    background-color: $cyan-50;
    h3{
        font-size: 2rem;
        font-weight: 800;
        color: $cyan-800;
    }
    p{
        margin-top: 1rem;
        margin-bottom: 2rem;
        max-width: 400px;
    }
    .btn-register{
        padding: 1rem 1.5rem;
        color: $cyan-800;
        font-weight: bold;
        border-radius: 8px;
        background: none;
        border: solid 1px $cyan-800;
        text-decoration: none;
    }
}

.footer{
    background-color: $zinc-800;
    color: white;
    font-size: 0.8rem;
    .logo{
        width: 60px;
    }
    .footer-logo-text{
        font-size: 1.6rem;
        font-family: 'Carter One';
        margin-left: 0.5rem;
    }
    h5{
        font-weight: 600;
        font-size: 1rem;
    }
    .social-links{
        display: flex;
        padding: 0;
        align-items: center;
        justify-content: center;
        li{
            list-style: none;
            padding: 0.5rem;
            .social-icons{
                width: 24px;
                height: 24px;
            }
        }
    }
    .quick-links{
        padding: 0;
        li{
                padding: 0.3rem 0;
                list-style: none;
            a{
                color: white;
                text-decoration: none;
            }
        }
    }
    .subfooter{
        background: $zinc-900;
        padding: 0.5rem;
        a,p{
            color: white;
            text-decoration: none;
            font-size: 0.6rem;
            padding: 0.5rem;
            margin: 0;
            text-align: center;
            display: inline;
        }
        .tgits{
            font-weight: 600;
            text-decoration: underline;
            cursor: pointer;
            font-size: 0.6rem;
        }
    }
}

#comments{
    height: 200px;
}

#contactus-submit-btn{
    background-color: $cyan-800;
    color: white;
    font-weight: bold;
}


.page-heading{
    font-size: 1.5rem;
}
.policy-heading{
    font-size: 1.1rem;
    font-weight: 600;
    margin-top: 1rem;
  }
.policy-para{
    font-size: 0.8rem;
}
.policy-list li{
    font-size: 0.8rem;
  }
@media (min-width:768px) {
   nav{
    img.logo{
        width: 80px;
        padding: 0.5rem;
    }
    }
    .hero-content{
        h1{
            margin-top: 4rem;
            
        }
        p{
            width: 75%;
        }
    }

    .footer{
        .social-links{
            display: flex;
            padding: 0;
            align-items: center;
            justify-content: space-between;
            
        }
    }
}

@media (min-width:1024px) {
    nav{
                .hamburger-icon{
            display: none;
        }
        .nav-items{
            display: flex;
            align-items: center;
            justify-content: end;
            flex-wrap: nowrap;
           .nav-item{
            font-size: 0.8rem;
            button{
                font-size: 0.8rem;
            }
           }
        }
    }
    .hero-content{
        h1{
            margin-top: 6rem;
            font-weight: 800;
            margin-right: 2rem;
            font-size: 3rem;
            width: 75%;
        }
        p{
            width: 75%;
        }
        .search-wrapper{
            padding-bottom: 4rem;
        }
    }

    .footer{
        .footer-logo{
            font-size: 2.5rem;
        }
    }

    .policy-heading{
        margin-top: 2rem;
      }
      .policy-para{
        font-size: 0.9rem;
      }
      .policy-list li{
        font-size: 0.9rem;
      }
}
@media (min-width:1200px){

.nav-items{
  
   .nav-item{
    font-size: 1rem;
    button{
        font-size: 1rem;
    }
   }
}
}

@media (min-width:1536px) {
    nav{
        
        .nav-items{
           .nav-item{
            button{
                font-size: 1rem;
                padding: 1rem 1.5rem;
            }
           }
            
        }
    }
    

}